// React Basic and Bootstrap
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Button,
  Label,
  Card,
  CardBody,
} from 'reactstrap';

// import seoSVG from '../../assets/images/illustrator/SEO_SVG.svg';
import VisionImg from '../../assets/images/ExploreKids/kid3-transformed.jpeg';
import MissionImg from '../../assets/images/ExploreKids/kid2-transformed.jpeg';

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [
        { title: 'Digital Marketing Solutions for Tomorrow1' },
        { title: 'Our Talented & Experienced Marketing Agency' },
        { title: 'Create your own skin to match your brand' },
      ],
      features1: [
        { title: 'Digital Marketing Solutions for Tomorrow11' },
        { title: 'Our Talented & Experienced Marketing Agency' },
        { title: 'Create your own skin to match your brand' },
      ],
      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section ">
          <Container>
            <div className="text-center">
              <h2> Why Explore Kids?</h2>
              <p>
                Children at every stage of their growth require different
                developmental needs and it is every parents and teachers
                responsibility to cater to the right developmental need and
                approach at the right time.
              </p>
            </div>

            <Row
              className="align-items-center"
              // data-aos="fade-up"
              // data-aos-duration="1800"
            >
              <Col lg="5" md="6" className="mt-4 pt-2">
                <img
                  src={VisionImg}
                  alt=""
                  style={{ height: '500px', width: '485px' }}
                />
              </Col>

              <Col lg="7" md="6" className="mt-4 pt-2">
                <div className="section-title ms-lg-5">
                  <h4 className="title mb-4">Our Vision</h4>
                  <p className="text text-align-justify">
                    At Explore Kids Preschool, our vision is to provide the best
                    early childhood education experience by fostering curiosity,
                    creativity, and a love for learning.
                  </p>
                  <ul className="list-unstyled text">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      A Playground for Creativity: Inspiring creativity through
                      art, music, storytelling, and imaginative play
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      A Gateway to Lifelong Learning: Equipping children with
                      foundational skills for a lifetime of learning and growth
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      A Bridge to Future Success: Preparing children for
                      academic excellence, social-emotional development, and
                      leadership roles
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      To create awareness among school teachers and students
                      about inclusive education.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      To provide professional support for child development.
                    </li>
                  </ul>
                  {/* <Link to="#" className="mt-3 h6 text-primary">
                    Find Out More{' '}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link> */}
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row
              className="align-items-center"
              // data-aos="fade-up"
              // data-aos-duration="1800"
            >
              <Col
                lg="7"
                md={{ size: 6, order: 1 }}
                xs={{ order: 2 }}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <div className="section-title">
                  <h4 className="title mb-4">Our Mission</h4>
                  <p className="text text-align-justify">
                    Our mission is to create a nurturing environment where
                    children can explore, discover, and grow through play-based
                    learning, fostering social, emotional, cognitive, and
                    physical development.
                  </p>
                  <ul className="list-unstyled text">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>{' '}
                      Supporting Social-Emotional Development: Nurturing
                      emotional intelligence, empathy, and positive
                      relationships
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Providing a Nurturing and Stimulating Environment for
                      Exploration and Discovery
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Fostering Creativity: Offering opportunities for artistic
                      expression, music, storytelling, and imaginative play
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Cultivating Compassionate and Curious Learners: Inspiring
                      children to become lifelong learners and compassionate
                      global citizens
                    </li>
                  </ul>
                  {/* <Link to="#" className="mt-3 h6 text-primary">
                    Find Out More{' '}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link> */}
                </div>
              </Col>

              <Col lg={5} md={6} className="order-1 order-md-2">
                <img
                  src={MissionImg}
                  alt=""
                  style={{ height: '500px', width: '485px' }}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default HowItWorks;
