// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Popup from '../../components/Layout/popup';

// Feature Images
import saas2 from '../../assets/images/ExploreKids/Founder.jpg';
import saas3 from '../../assets/images/ExploreKids/Director.jpg';

import Section from './Home';
import { agencyFeatureArray } from '../../common/data';

const IndexAgency = () => {
  return (
    <React.Fragment>
      {/* Feature */}
      <section className="section">
        <Container className="mt-80 mt-50">
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <img src={saas2} className="img-fluid" alt="" />
            </Col>

            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                <h4 className="title mb-3">Founder</h4>
                <p className="text-muted" style={{ textAlign: 'justify' }}>
                  <strong>Mr. Lokesh</strong>, a visionary leader whose journey
                  began in the fertile lands of Bangalore South. Raised in a
                  family deeply rooted in agriculture, Lokesh embodies the
                  resilience and dedication of his farming heritage. With over a
                  decade of experience in the education sector, he has been
                  instrumental in shaping the minds of future generations.His
                  passion for education extends beyond the confines of
                  classrooms, as he has tirelessly worked to uplift numerous
                  schools, imparting knowledge and fostering growth. For the
                  past eight years, Lokesh has been at the helm of Saksharika
                  Vidhya Varshini, a renowned institution known for its
                  excellence in education.Under his adept guidance, Saksharika
                  Vidhya Varshini has flourished into a beacon of learning,
                  empowering students to strive for academic excellence while
                  nurturing their holistic development. Lokesh's leadership and
                  unwavering commitment to education continue to inspire both
                  students and educators alike, leaving an indelible mark on the
                  educational landscape.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center mt-100 mt-60">
            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                <h4 className="title mb-3">Director</h4>
                <p className="text-muted" style={{ textAlign: 'justify' }}>
                  <strong>Ms. Pushpa Chhabria</strong> She comes with a
                  background of a decade's experience in the education industry.
                  She started her career as an assistant teacher. She has headed
                  five preschools as a franchise head for a renowned preschool
                  chain in Bangalore. She has also worked for a main stream
                  school as a startup team and comes with a lot of passion and
                  enthusiasm towards children and parent counseling. Ms Pushpa
                  Chhabria, who comes with an experience of more than a decade
                  in the field of schooling designed an array of activities
                  pertaining to every concept of providing a state - of – art
                  infrastructure to fulfil the children’s requirements and also
                  our focus is to create a conducive learning atmosphere,
                  keeping in pace with the best practice in the industry.
                </p>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <img src={saas3} className="img-fluid" alt="" />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexAgency;
